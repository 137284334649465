<template>
    <div>
        <el-card>
            <el-page-header :content="title" style="margin-bottom: 30px;" @back="goBack" />
            <nav class="input">
                <el-input v-model="search.keyword" size="medium" placeholder="输入姓名，昵称，公司，手机" />
            </nav>
            <nav class="input" style="width:150px">
                <el-select v-model="search.status" placeholder="活动状态">
                    <el-option label="全部" :value="-2" />
                    <el-option label="已取消" :value="-1" />
                    <el-option label="待审核" :value="0" />
                    <el-option label="审核通过" :value="1" />
                    <el-option label="审核不通过" :value="2" />
                    <el-option label="已签到" :value="3" />
                </el-select>
            </nav>
            <nav class="input" style="width:150px">
                <el-select v-model="search.channelId" placeholder="渠道">
                    <el-option label="全部" :value="-1" />
                    <el-option v-for="(item, index) in channelList" :label="item.name" :value="item.id" :key="index" />
                </el-select>
            </nav>
            <nav class="input" style="width:150px">
                <el-select v-model="search.ticketId" placeholder="票种">
                    <el-option label="全部" :value="-1" />
                    <el-option v-for="(item, index) in ticketList" :label="item.name" :value="item.id" :key="index" />
                </el-select>
            </nav>
            <nav class="input" style="width:150px">
                <el-select v-model="search.pay" placeholder="支付金额">
                    <el-option label="全部" :value="-1" />
                    <el-option label="免费" :value="1" />
                    <el-option label="付费" :value="2" />
                </el-select>
            </nav>
            <nav class="input" style="width: 380px;">
                <label for="" style="font-size: 14px; float: left; transform: translateY(9px);height: 40px;"
                    >报名时间：</label
                >
                <el-date-picker
                    v-model="search.applyDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 300px"
                    size="medium"
                />
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button type="primary" size="small" @click="onSearch">{{ btn ? "搜索" : "loading.." }}</el-button>
                <el-button type="primary" size="small" @click="onReset">{{ btn ? "重置" : "loading.." }}</el-button>
            </nav>

            <nav class="input" style="width: auto;height:40px">
                <el-button type="primary" size="small" @click="add">{{ "添加活动人员" }}</el-button>
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button type="checkbox" style="background: #409eff;color: #ffffff" size="small" @click="beforederivedForm">{{
                    exportLoad ? "加载中..." : "导出表格"
                }}</el-button>
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="导入EXCEL，批量添加报名，右边的按钮可以下载EXCEL模板 "
                    placement="top-start"
                >
                    <el-button type="primary" :loading="addActivityLoading" size="small" @click="uploadDocument"
                        >批量添加活动人员<i class="el-icon-upload el-icon--right"></i
                    ></el-button>
                </el-tooltip>
                <el-button type="primary" size="small" @click="downloadTemplate">{{ "下载EXCEL模板文档" }}</el-button>
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button type="primary" size="small" @click="batchReview(1)">{{ "批量审核通过" }}</el-button>
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button type="primary" size="small" @click="batchReview(2)">{{ "批量审核不通过" }}</el-button>
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button
                    v-if="$store.state.btn_if.indexOf('user_list_operation_remark') != -1"
                    type="primary"
                    size="small"
                    @click="operationalRemarks"
                    >{{ "批量清空运营备注" }}</el-button
                >
            </nav>
            <nav class="input" style="width: auto;height:40px">
                <el-button type="primary" size="small" @click="$refs.sendSms.init({ ...search, activityId: id })">{{
                    "添加到短信收件人"
                }}</el-button>
              <el-button type="primary"
                         size="small" @click="handleSms()">{{
                  "人工审核通过短信自定义"
                }}</el-button>
              <el-dropdown @command="handleCommand">
  <span class="el-dropdown-link">
    <el-button type="primary"
               size="small" style="margin-left: 10px">
      短信发送<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="0" >发送短信</el-dropdown-item>
                  <el-dropdown-item command="1">查看发送记录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </nav>
          <nav class="input" style="width: auto;height:40px">
            <el-dropdown>
  <span class="el-dropdown-link">
    <el-button type="primary"
               size="small" style="margin-left: 10px">
      模版通知<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
  </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">
                  <span @click="handleModelInform(1)">
                    发送模板通知
                  </span></el-dropdown-item>
                <el-dropdown-item command="1">
                  <span @click="handleModelInform(0)">查看发送记录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </nav>
        </el-card>
        <el-card style="margin-top: 10px">
<!--          <el-checkbox v-model="checkedPage" @change="handleCurrentTable('page')">本页全选</el-checkbox>-->
<!--          <el-checkbox v-model="checkedAll" @change="handleCurrentTable('all')">当前名单全选</el-checkbox>-->
            <el-table
                ref="table"
                :data="list"
                style="width: 100%"
                border
                stripe
                @selection-change="selChange"
                :header-cell-style="rowClass"
                :row-key="
                    (row) => {
                        return row.id;
                    }
                "
            >
                <el-table-column type="selection" :reserve-selection="true" width="55" />
                <el-table-column prop="id" label="ID" />
                <el-table-column prop="userId" label="UID" />
                <el-table-column prop="avatar" label="头像" width="140">
                    <template slot-scope="{ row }">
                        <!-- <img :src="baseurl+yu+row.avatar||baseurl + yu + '/static/pc/img/avatar.png'" alt=""> -->
                        <el-image :src="baseurl + yu + row.avatar" :lazy="true" style="width:90px">
                            <div slot="error">
                                <img :src="baseurl + yu + '/static/pc/img/avatar.png'" alt="" style="width:90px" />
                            </div>
                        </el-image>
                        <p>
                            <a :href="row.h5" target="blank">（电子票）</a>
                        </p>
                        <!-- <p>
              <div v-if="row.userAuth != undefined">
                <el-tag v-if="row.userAuth == 1" type="success" size="small">已认证</el-tag>
                <el-tag v-else type="danger" size="small">未认证</el-tag>
              </div>
            </p> -->
                    </template>
                </el-table-column>
                <el-table-column prop="realName" label="名字">
                    <template slot-scope="{ row }">
                        <p>
                            {{ row.realName || row.nickname }}
                        </p>
                        <template v-if="activityNeedAuth == 1">
                            <p v-if="row.userAuth == 1" style="color: green;">已实名认证</p>
                            <p v-else style="color: red;">未实名认证</p>
                            <p v-if="row.authType">({{ row.authType }})</p>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="company" label="公司" />
                <el-table-column prop="job" label="职位" />
                <el-table-column prop="ticketName" label="票种" width="165">
                    <template slot-scope="{ row }">
                        <p>{{ row.ticketName }}</p>
                        <!-- ticketType 1是付费票-->
                        <div style="color:skyblue" v-if="row.ticketType == 1">
                            <div>原价：{{ row.ticketPrice }}元</div>
                            <div>早鸟优惠：{{ row.ticketDiscount }}元</div>
                            <div>优惠券：{{ row.couponDiscount }}元</div>
                            <div v-if="row.couponCode">优惠码：{{ row.couponCode }}</div>
                            <div v-if="row.useBonus">积分抵扣：{{ row.useBonus }}元</div>
                            <div v-if="row.bargainDiscount">砍价优惠：{{ row.bargainDiscount }}元</div>
                        </div>

                        <div style="color:skyblue" v-if="row.couponId">(ID:{{ row.couponId }})</div>
                        <div style="color:red" v-if="row.applyTicketType">(团体票)</div>
                    </template>
                </el-table-column>
                <el-table-column label="支付金额">
                    <template slot-scope="{ row }">
                        {{ row.realPrice }}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <el-tag v-show="fStatus(row.status)" :type="fcolor(row.status)" size="small">{{
                            fStatus(row.status)
                        }}</el-tag>
                        <p v-if="row.failReason">审核不通过原因：{{ row.failReason }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="channelName" label="报名渠道">
                    <template slot-scope="{ row }">
                        <!-- {{row.channelName || }} -->
                        {{ row.userId ? row.channelName : "未注册" }}
                    </template>
                </el-table-column>

                <el-table-column label="备注" prop="remark">
                    <template slot-scope="{ row }">
                        <Remark
                            v-show="row.userId"
                            :row="row"
                            :permissions="true"
                            :val="{ url: '/admin/User/editInfo', pw: 'user_list_remark', userId: true,user:'是否将备注同步至高管库和内部会员库' }"
                            :getlist="getlist"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="运营备注" prop="remark">
                    <template slot-scope="{ row }">
                        <Remarks
                            idKey="userId"
                            v-show="row.userId && $store.state.btn_if.indexOf('user_list_operation_remark') != -1"
                            :row="row"
                            rowKey="operationRemark"
                            :permissions="true"
                            url="/admin/User/editOperationRemark"
                            @getlist="getlist"
                        />
                        <template v-if="$store.state.btn_if.indexOf('user_list_operation_remark') == -1">
                            {{ row.operationRemark }}</template
                        >
                    </template>
                </el-table-column>
                <el-table-column label="小助手" prop="waId" width="140px">
                    <template slot-scope="{ row }">
                        <!-- {{row.waId}} -->
                        <el-select
                            v-show="row.userId"
                            v-model="row.waId"
                            placeholder="小助手"
                            size="medium"
                            multiple
                            :multiple-limit="3"
                            @change="handleInputBlur(row)"
                            @visible-change="visibleChange"
                            @remove-tag="visibleChange()"
                        >
                            <el-option
                                :key="index"
                                v-for="(item, index) in $store.state.NormalList"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                      <Remark
                          :idKey="'userId'"
                          style="margin-top: 20px"
                          :row="row"
                          :rowKey="'crowd'"
                          url="/admin/User/editCrowd"
                          @getlist="getlist"
                          :val="{ url: '/admin/User/editCrowd',pw:'user_list_service', user:'是否将备注同步内部用户' }"

                      ></Remark>
                    </template>
                </el-table-column>

                <el-table-column prop="createTime" label="报名时间" />
                <el-table-column label="操作" width="165">
                    <template slot-scope="{ row }">
                        <el-button
                            v-show="row.forms"
                            type="text"
                            icon="el-icon-video-camera-solid"
                            size="mini"
                            class="skyblue"
                            @click="watchInfo(row)"
                            >查看报名信息</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-camera"
                            size="mini"
                            class="green"
                            @click="seeTheLink(row.id)"
                            >查看联系方式</el-button
                        >
                        <el-button
                            v-if="row.status == 0 || row.status == 2"
                            type="text"
                            icon="el-icon-s-check"
                            size="mini"
                            class="yellow"
                            @click="audit(row, 1)"
                            >审核通过</el-button
                        >
                        <el-button
                            v-if="row.status == 1 || row.status == 0"
                            type="text"
                            icon="el-icon-s-check"
                            size="mini"
                            class="yellow"
                            @click="audit(row, 2)"
                            >审核不通过</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-s-flag"
                            size="mini"
                            style="color:MediumSlateBlue"
                            @click="enrollment(row)"
                            >活动签到</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-s-release"
                            size="mini"
                            style="color:HotPink"
                            @click="cancellationOfRegistration(row)"
                            >取消活动报名</el-button
                        >

                        <!-- <el-button
                            type="text"
                            icon="el-icon-view"
                            size="mini"
                            style="color:orange"
                            @click="seeTheSignIn(row)"
                            >查看签到信息</el-button
                        > -->
                    </template>
                </el-table-column>
            </el-table>
          <pop ref="popNoticeList" :title="popTitle" @toAudit="$refs.popNoticeList.show=false"   width="800px">
            <el-table
                ref="table"
                :data="noticeList"
                style="width: 100%"
                border
                stripe
                @selection-change="selChange"
                :header-cell-style="rowClass"
                :row-key="
                    (row) => {
                        return row.id;
                    }
                "
            >
              <el-table-column prop="id" label="ID" />
              <el-table-column prop="userRealName" label="姓名" />
              <el-table-column prop="status" label="状态">
                <template slot-scope="{ row }">
                  {{row.status==0?'未发送':row.status==1?'已发送':'发送异常'}}
                </template>
              </el-table-column>
              <el-table-column prop="status" label="时间" width="250px">
                <template slot-scope="{ row }">
                  <div> 创建时间：{{row.createTime}}</div>
                  <div v-if="row.status!=0"> 发送时间：{{row.completeTime}}</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :current-page="noticeListForm.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="noticeListForm.pageSize"
                layout="total, prev, pager, next"
                :total="noticeListtotal"
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
            />
          </pop>
          <pop ref="popNotice" :title="popTitle"  @toAudit="submitNotice" width="800px">
            <el-form :model="noticeForm" ref="noticeForm" :rules="rules" label-width="80px" :inline="false" size="normal">
              <el-form-item v-if="popTitle=='模板通知'" label="收件人">
                <div  style="margin-bottom: 20px;" class="flex-align" >
                  <el-radio v-model="radioChange" label="0" @change="handleTable()">当前列表所勾选的用户</el-radio>
                  <el-radio v-model="radioChange" label="1" @change="handleTable()">当前条件下的所有用户</el-radio>
                  <el-popover placement="top" title=""  trigger="hover"
                              content="">
                    <img style="" src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/admin/notice.jpg" alt="">
                    <div slot="reference">
                      <div class="btn claimBusiness btn-center">
                        <el-button type="text">查看模板通知示例</el-button>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </el-form-item>
             <div></div>
              <el-form-item class="input-item" label="会议名称" prop="activityName">
                <el-input maxlength="16" placeholder="请输入会议名称" v-model="noticeForm.activityName"></el-input>
              <span class="length">{{noticeForm.activityName.length}}/16</span>
              </el-form-item>
              <el-form-item class="input-item" label="到场时间" prop="beginTime">
                <el-input maxlength="16" placeholder="请输入到场时间" v-model="noticeForm.beginTime"></el-input>
                <span class="length">{{noticeForm.beginTime.length}}/16</span>
              </el-form-item>
              <el-form-item class="input-item" label="签到时间" prop="signTime">
                <el-input maxlength="16" placeholder="请输入签到时间" v-model="noticeForm.signTime"></el-input>
                <span class="length">{{noticeForm.signTime.length}}/16</span>
              </el-form-item>
              <el-form-item class="input-item" label="签到地点" prop="signAddress">
                <el-input maxlength="16" placeholder="请输入签到地点" v-model="noticeForm.signAddress"></el-input>
                <span class="length">{{noticeForm.signAddress.length}}/16</span>
              </el-form-item>
              <el-form-item class="input-item" label="服务项目" prop="service">
                <el-input maxlength="16" placeholder="请输入服务项目" v-model="noticeForm.service"></el-input>
                <span class="length">{{noticeForm.service.length}}/16</span>
              </el-form-item>
            </el-form>
          </pop>
          <pop ref="pop" :title="popTitle"  @toAudit="submit">
            <el-form :model="smsForm" ref="smsForm" :rules="rules" label-width="80px" :inline="false" size="normal">
             <el-form-item v-if="popTitle=='发送短信'" label="收件人">
               <div  style="margin-bottom: 20px;">
                 <el-radio v-model="radioChange" label="0" @change="handleTable()">当前列表所勾选的用户</el-radio>
                 <el-radio v-model="radioChange" label="1" @change="handleTable()">当前条件下的所有用户</el-radio>
               </div>
             </el-form-item>
              <el-form-item label="短信内容">
                <div>
                  <el-col :span="22">
                    <el-input
                        placeholder="请输入模板内容"
                        :rows="8"
                        type="textarea"
                        v-model="smsForm.applyCheckSuccessTemplate"
                        show-word-limit
                        maxlength="490"
                    ></el-input>
                  </el-col>
                </div>
                <div style="clear: both; line-height: 1.8; width: 60%;padding-top: 10px;color: #fda400;">
                  <div class="tips" style="color: #737474">
                    当前 <span style="color: red">{{ smsForm.applyCheckSuccessTemplate.length }}</span> 字，预计发送条数约为
                    <span style="color: red">{{contentArticle}}</span>
                    条。实际发送时，签名、变量长度会影响总字数。
                  </div>
                  <div>
                    【注意事项】<br />
                    1、文案前面不用加[扬帆出海]，发出时会自动加上 <br />
                    2、文案最后需要手动加上“拒收请回复R” <br />
                    3、链接前后需要空格<br />
                    4、文案中不要出现【】符号
                  </div>
                </div>
                <div style="color: red ;line-height: 20px">
                         示例： CJ游友必打卡！[7.24-7.27]与扬帆出海开启4日上海GAME之旅！1场游戏主题峰会+3大主题派对，集结数百家海内外头部厂商，汇聚5000+游戏行业出海精英，资讯/干货/人脉/合作/壕礼统统拿下！即刻报名： http://yfch.cc/eP88m9 ，拒收请回复R
                        </div>
              </el-form-item>
            </el-form>
          </pop>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <Dialog :id="3" ref="dialogRef" :cet="true" :title="'添加活动人员'" @getlist="getlist" />
        <Dialog :id="4" ref="dialogRef1" :cet="true" :title="'审核拒绝'" @getlist="getlist" />
        <Slink ref="link" />
        <Drawers ref="drawer" :title="'展示表单'" />
        <Drawers ref="wTable" title="签到信息"></Drawers>
        <sendPop ref="sendPop" @download="derivedForm"></sendPop>
        <send-sms ref="sendSms" type="2"></send-sms>
    </div>
</template>

<script>
import * as path from "path";

let that;
// import rules from '../../utils/rules'
import Remark from "@/components/remark/index.vue";
import Remarks from "@/components/global/Remark/index.vue";
import mixins from "@/mixin/list";
import sendPop from "@/components/countDowns/sendPop";
export default {
    components: {
        Remark,
        Remarks,
        Dialog: () => import("@/components/popup.vue"),
        Slink: () => import("@/components/seeTheLink.vue"),
        sendPop,
        // Drawer: () => import('@/components/Drawer')
    },
    mixins: [mixins],
    data() {
        return {
          noticeList:[],
          noticeListtotal:0,
          popType: false,
          checkedPage: false,
          checkedAll: false,
          radioChange: '0',
          popTitle: '人工审核通过短信自定义',
          btn: true,
          action: {
            action: "activity",
          },
          id: 0,
          exportLoad: false,
          pagesize: 10,
          addActivityLoading: false,
          search: {
            page: 1,
            pageSize: 10,
            keyword: "",
            channelId: "",
            ticketId: "",
            status: "",
            applyDate: [],
            pay: "",
          },
          noticeListForm:{
            page: 1,
            pageSize: 10,
          },
          title: "详情页",
          s_form: {
            page: 1,
            pageSize: 10,
            keyword: "",
            channelId: "",
            ticketId: "",
            status: "",
            applyDate: [],
            // order: ''
          },
          forms: [],
          channelList: [],
          ticketList: [],
          theSelected: [],
          userIdArr: [],
          activityNeedAuth: 0,
          checkPhone: 0,
          smsForm: {

            applyCheckSuccessTemplate: '',
            selectionType: 1
          },
          noticeForm:{
            activityName:'',
            beginTime:'',
            signTime:'',
            signAddress:'',
            service:''
          },
          rules: {
            activityName: [
              { required: true, message: '请填写会议名称', trigger: 'blur' }
            ],
            beginTime: [
              { required: true, message: '请填写到场时间', trigger: 'blur' }
            ],
            signTime: [
              { required: true, message: '请填写签到时间', trigger: 'blur' }
            ],
            signAddress: [
              { required: true, message: '请填写签到地点', trigger: 'blur' }
            ],
            service: [
              { required: true, message: '请填写服务项目', trigger: 'blur' }
            ],
          }
        }
    },
  computed: {
    contentArticle() {
      var length = this.smsForm.applyCheckSuccessTemplate.length;
      return Math.ceil(length / 67);
    },
  },
    beforeCreate() {
        that = this;
    },
    updated() {
        //   console.log(this.form);
    },

    created() {
        // this.getconfig()
        // this.getconfig('user')
        this.getlist();
        this.getConfiguration();
        sessionStorage.setItem("action", "cooperation");
    },
    mounted() {},
    methods: {
      async handleModelInform(type){
        if(type==1){
          this.popTitle='模板通知'
          this.$refs.popNotice.show=true
          this.noticeForm={
            activityName:'',
            beginTime:'',
            signTime:'',
            signAddress:'',
            service:''
          }
        }else {
          this.popTitle='模版发送记录'
          this.$refs.popNoticeList.show=true
          let  {data} =await this.$http.get("/admin/Activity/getSignNoticeLogList", {params: {activityId: this.$route.query.id,...this.noticeListForm}})
          console.log(data)
          this.noticeList=data.data.list
          this.noticeListtotal=data.data.totalCount
        }

      },
      handleTable(){
        if(this.radioChange ==1){
          this.smsForm.selectionType=2
        }else {
          this.smsForm.selectionType=1
        }
        console.log(this.smsForm.selectionType)
      },
      // 表格全选
      // handleCurrentTable(type){
      //   if(type=='page'){
      //     if(this.checkedPage){
      //       this.$refs.table.clearSelection()
      //       this.$refs.table.toggleAllSelection()
      //       this.search.selectionType=1
      //     }else {
      //       this.$refs.table.toggleAllSelection()
      //     }
      //   }else {
      //     if(type='all'){
      //
      //     }
      //   }
      // },
      /**
       * 短信下拉框选择监听
       * @returns {Promise<void>}
       */
      handleCommand(command){
        if(command==0){
          this.popTitle='发送短信'
          if(this.popType){
            this.smsForm.applyCheckSuccessTemplate=''
            this.popType=false
            this.$refs.pop.show=true
          }else {
            this.$refs.pop.show=true
          }
        }else if(command == 1) {
          this.$router.push({path: `/sms_task_list?activityId=${this.$route.query.id}`})
        }
      },
      async getSmsDetail(){
        let  {data} =await this.$http.get("admin/Activity/getById", {params: {id: this.$route.query.id}})
        this.smsForm.applyCheckSuccessTemplate=data.data.applyCheckSuccessTemplate
        console.log(data)
      },
      handleSms(){
        if(!this.popType){
          this.popType =true
        }
        this.popTitle='人工审核通过短信自定义'
        this.$refs.pop.show=true
        this.getSmsDetail()
        this.smsForm.id=this.$route.query.id
      },
      async submitNotice(){
        this.$refs['noticeForm'].validate((valid) => {
          if (valid) {
            if (!this.$route.query.id) {
              return;
            }
            this.id = this.$route.query.id;
            var form =  this.search;
            this.$http
                .get("/admin/Activity/getSignNoticeTotal", {
                  params: {
                    ...form,
                    applyDate: form.applyDate && form.applyDate.join("~"),
                    activityId: this.$route.query.id,
                    type:this.smsForm.selectionType || 1,
                    ids:this.theSelected.join(',') || '',
                  },
                })
                .then(({ data: res }) => {
                  if (res.errorCode == 200) {
                    const sendSignNotice = async ()=>{
                      let  {data} =await this.$http.post("/admin/Activity/sendSignNotice",{
                        type:this.smsForm.selectionType || 1,
                        ids:this.theSelected.join(',') || '',
                        activityId:this.$route.query.id,
                        ...form,
                        ...this.noticeForm
                      })
                      if(data.errorCode == 200){
                        this.$message({
                          message: '模版已发送',
                          type: 'success'
                        });
                        this.$refs.popNotice.show=false
                        this.noticeForm={
                          activityName:'',
                          beginTime:'',
                          signTime:'',
                          signAddress:'',
                          service:''
                        }
                      }
                    }
                    this.$confirm(this.smsForm.selectionType==2?`当前模板通知将发送给当前条件下且已关注公众号的（共${res.data.total}个)`:`当前模板通知将发送给列表勾选且已关注公众号的${res.data.total}个用户`, '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      sendSignNotice()
                      this.$refs.pop.show=false


                    }).catch(() => {
                      this.$message({
                        type: 'info',
                        message: '取消'
                      });

                    });
                  }
                });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async submit(){
        if(this.popTitle == '发送短信'){
          this.getlist()
          if(this.search.applyDate.length){
            this.search.applyDate=this.search.applyDate.join('~')
          }
          const sendSms = async ()=>{
            let  {data} =await this.$http.post("/admin/Activity/sendSms",{
              type:this.smsForm.selectionType || 1,
              smsContent:this.smsForm.applyCheckSuccessTemplate,
              ids:this.theSelected.join(',') || '',
              activityId:this.$route.query.id,
              params:JSON.stringify(this.search)
            })
            if(data.errorCode == 200){
              this.$message({
                message: '短信已发送',
                type: 'success'
              });
            }
          }
          this.$confirm(this.smsForm.selectionType==2?`短信将发送给当前条件下所有用户（共${this.total}个)`:`当前短信将发送给列表勾选的${this.theSelected.length}个用户`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            sendSms()
            this.$refs.pop.show=false
            this.smsForm={
              applyCheckSuccessTemplate:'',
            }

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '取消'
            });
          });
        }else {
          let  {data} =await this.$http.post("/admin/Activity/setApplyCheckSuccessTemplate",this.smsForm)
          this.$refs.pop.show=false
          this.smsForm={
            applyCheckSuccessTemplate:'',
          }
        }
      },
        seeTheLink(val) {
            this.$refs.link.getActivityDetails(val);
        },
        seeTheSignIn(row) {
            // console.log(row);
            this.$refs.wTable.acId = row.id;
            this.$refs.wTable.init(10);
            this.$refs.wTable.currentPage = 1;
            this.$refs.wTable.getlist();
        },
        selChange(val) {
            this.theSelected = val.map((item) => {
                return item.id;
            });
            this.userIdArr = val.map((item) => item.userId);
        },
        async batchReview(status = 1) {
            if (status == 2) {
                var { value: remark } = await this.$prompt("请输入审核备注", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                });
            }
            let { data: res } = await this.$http.post("/admin/Activity/applyCheckAll", {
                ids: this.theSelected.toString(),
                status,
                remark,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
            this.$refs.table.clearSelection();
        },
        async operationalRemarks() {
            let { data: res } = await this.$http.post("/admin/User/deleteOperationRemark", {
                id: this.userIdArr.toString(),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
            this.$refs.table.clearSelection();
        },
        downloadTemplate() {
            location.href = "https://admin.yfchuhai.com/xlsx/theTemplate.xlsx";
        },
        uploadDocument() {
            var input = document.createElement("input");
            input.type = "file";
            input.accept = ".xlsx";
            input.click();
            input.addEventListener("change", async (e) => {
                const files = e.target.files;
                var formData = new FormData();
                formData.append("activityId", this.id);
                formData.append("file", files[0]);
                this.addActivityLoading = true;
                let { data: res } = await this.$http.post("/admin/Activity/importExcel", formData, {
                    timeout: 5000000,
                });
                this.addActivityLoading = false;
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
                input.remove();
            });
        },
        async enrollment(val) {
            try {
                await this.$confirm("请确认无误后点确定！ 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                let { data: res } = await this.$http.post("/admin/Activity/sign", {
                    id: val.id,
                    activityId: this.id * 1,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消",
                });
            }
        },
        async cancellationOfRegistration(val) {
            try {
                await this.$confirm("请确认无误后点确定！ 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                let { data: res } = await this.$http.post("/admin/Activity/cancelApply", {
                    id: val.id,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消",
                });
            }
        },
        beforederivedForm() {
            // if(this.detai)
            if (this.checkPhone == 1) {
                this.$refs.sendPop.init();
                return;
            }
            this.exportLoad = true;
            this.derivedForm({}, this.done);
        },
        done() {
            this.exportLoad = false;
        },
        async derivedForm(val, done) {
            var form = this.search;
            try {
                const { data: res } = await this.$http.get("/admin/Activity/exportApplyExcel", {
                    params: {
                        ...form,
                        applyDate: form.applyDate && form.applyDate.join("~"),
                        activityId: this.$route.query.id,
                        ...val,
                    },
                });
                done();
                if (res.data.url) {
                    window.location.href = res.data.url;
                }
            } catch (error) {
                done();
            }
        },
        onReset() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.getlist();
        },
        handleSizeChange(e) {
            this.pagesize = e;
            // console.log(e)
        },
      handleSizeChange1(e) {
  noticeListForm.pagesize=e
},
        watchInfo(row) {
            this.$refs.drawer.drawer = true;
            this.$refs.drawer.interfaces = 4;
            if (row.forms) {
                this.$refs.drawer.ruleForm = JSON.parse(row.forms);
            }
        },
      visibleChange(e){
        if(e){
          return
        }else {
          const post =async (type)=>{
            var { data: res } = await this.$http.post("/admin/User/editInfo", {
              ...this.row,
              id: this.row.userId,
              waId: this.row.waId.toString(),
              sync: type

            });
            if (res.errorCode == 200) {
              this.$message.success(res.message);
              this.getlist();
            }
          }
          this.$confirm(' 是否将小助手同步至内部用户', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
          }).then(() => {
            post(1)


          }).catch(() => {
            post(0)


          });

        }


},
         handleInputBlur(val) {
        this.row=val

        },
        fStatus: function(val) {
            if (val == 0) {
                return "待审核";
            } else if (val == -1) {
                return "已取消";
            } else if (val == 1) {
                return "审核通过";
            } else if (val == 2) {
                return "审核不通过";
            } else if (val == 3) {
                return "已签到";
            }
        },
        fcolor(val) {
            if (val == -1 || val == 2) {
                return "info";
            } else if (val == 1) {
                return "defult";
            } else if (val == 0) {
                return "warning";
            } else if (val == 3) {
                return "success";
            }
        },
        handleCurrentChange(e) {
            this.s_form.page = e;
            this.search.page = e;
            this.getlist(this.s_form);
            this.$nextTick(() => {
                document.querySelector(".el-main").scrollTop = 0;
            });
        },
      async handleCurrentChange1(e) {
        this.noticeListForm.page = e;
        console.log(e)

        let  {data} =await this.$http.get("/admin/Activity/getSignNoticeLogList", {params: {activityId: this.$route.query.id,...this.noticeListForm}})
        console.log(data,1)
        this.noticeList=data.data.list
        this.noticeListtotal=data.data.totalCount
      },


        onSearch() {
            this.search.page = 1;
            this.s_form = { ...this.search };
            this.getlist();
        },
        getlist(val) {
            if (!this.$route.query.id) {
                return;
            }
            this.id = this.$route.query.id;
            var form = val || this.search;
            this.$http
                .get("/admin/Activity/getApplyList", {
                    params: {
                        ...form,
                        applyDate: form.applyDate && form.applyDate.join("~"),
                        activityId: this.$route.query.id,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.list = res.data.list;
                        this.list.forEach((item) => {
                            if (item.waId) {
                                item.waId = item.waId.split(",");
                            }
                        });
                        this.total = res.data.totalCount;
                        this.title = res.data.activity.title;
                        this.forms = res.data.activity.forms;
                        this.checkPhone = res.data.activity.checkPhone;
                        this.activityNeedAuth = res.data.activity.activityNeedAuth;
                    }
                });
        },

        async getConfiguration() {
            const { data: res } = await this.$http.get("/admin/Activity/getChannelList", {
                params: {
                    activityId: this.$route.query.id,
                    page: 1,
                    pageSize: 100000,
                },
            });
            this.channelList = res.data.list;
            const { data: resTicket } = await this.$http.get("/admin/Activity/getTicketList", {
                params: {
                    activityId: this.$route.query.id,
                    page: 1,
                    pageSize: 100000,
                },
            });
            this.ticketList = resTicket.data.list;
            // console.log(resTicket);
        },

        async add() {
            this.$refs.dialogRef.dialogVisible = true;
            this.$refs.dialogRef.form = {
                userId: "",
                activityId: this.$route.query.id,
                ticketId: "",
                ticketList: this.ticketList,
                type: "0",
                ticketDesc: "",
            };
        },

        async audit(val, num) {
            // console.log(val);
            if (num == 1) {
                var { data: res } = await this.$http.post("/admin/Activity/applyCheck", {
                    id: val.id,
                    status: 1,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            } else {
                this.$refs.dialogRef1.dialogVisible = true;
                this.$refs.dialogRef1.form = {
                    remark: val.failReason,
                    status: 2,
                    id: val.id,
                };
            }
        },
        open(id) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.post("/admin/Activity/managerDel", { id: id }).then((res) => {
                        if (res.data.errorCode == 200) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            if (this.total % 10 == 1 && this.total != 1) {
                                this.search.page--;
                            }
                            this.getlist();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    },
};
</script>

<style lang="less" scoped>
.input-item{
  position: relative;
  .length{
    position: absolute;
    top: 2px;
    right: 20px;
    color: #9c9b9b;
  }
}
.out {
    // width: 500px;

    margin-bottom: 20px;
    .input {
        width: 37%;
        display: inline-block;
        margin-right: 30px;
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.red {
    color: red;
}
.out .input {
    width: 18%;
}
.el-card {
    position: relative;
}
.out .right {
    float: right;
}
.box {
    display: inline-block;
    margin: 5px;
}
</style>
